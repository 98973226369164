import { Button, Form, notification, Switch, Select, Input, Popconfirm, Radio, Row, Space, Typography, Tooltip, Alert, Affix, Divider } from 'antd'
import styles from './CompanySettings.module.css'
require('./CompanySettings.less')
import { useRouter } from 'next/router'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Companies } from '../../services/api/companies'
import { SalesOrders } from '../../services/api/sales-orders'
import { Users } from '../../services/api/users'
import { updateCompanyDetails } from '../../store/companies/actions'
import { capitalizeWord, convertEnumToString, isNotEmpty, isValidUUID } from '../../utils'
import { DistributorOrderNotificationValueType, industries as Industries, InvoiceScanTypes, SubscriptionLimit, SubscriptionPeriod, SubscriptionStatus, SubscriptionTier, ecommerceIndustryTypeOptions } from '../../utils/constants'
import { Apps, claimsRoles, InventoryEditions, InvoiceTypes } from '../../utils/constants'
import ImageUpload from '../image-upload'
import { ArrowRightOutlined, InfoCircleOutlined, EditOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { usePrevious } from '../../hooks/usePrevious'
import ClearCompanyDataModal from '../clear-company-data-modal'
import { Permissions } from '../../services/api/permissions'
import NumberInput from '../number-input'
import TextInput from '../text-input'
import StoreIntegrations from '../store-integrations'
import { Inventory } from '../../services/api/inventory'

export const Industry = {
	GENERAL: 'general',
	STEEL: 'steel',
	AGRICULTURE: 'agriculture'
}

const { Text } = Typography

const industryOptions = [
	{
		value: 'E_COMMERCE',
		label: 'E-Commerce'
	},
	{
		value: 'ENTERPRISE',
		label: 'Enterprise'
	}
]

const appOptions = [
	{
		title: 'OMS',
		tooltip: 'Order Management System',
		value: Apps.OMS,
		icon: '/img/app_oms.svg'
	},
	{
		title: 'DMS',
		tooltip: 'Delivery Management System',
		value: Apps.DMS,
		icon: '/img/app_dms.svg'
	},
	{
		title: 'WMS',
		tooltip: 'Warehouse Management System',
		value: Apps.WMS,
		icon: '/img/app_wms.svg'
	},
	{
		title: 'TMS',
		tooltip: 'Transport Management System',
		value: Apps.TMS,
		icon: '/img/app_tms.svg'
	}
]

const defaultStatusNotificationsOptions = {
	isRequestedNotificationEnabled: false,
	isApprovedNotificationEnabled: false,
	isShippedNotificationEnabled: false,
	isInTransitNotificationEnabled: false,
	isUnloadedNotificationEnabled: false,
	isCancelledNotificationEnabled: false,
	isCompletedNotificationEnabled: false
}

const defaultBulkUploadFlags = {
	isOrderUploadEnabled: false,
	isCustomerUploadEnabled: false,
	isPurchaseOrderUploadEnabled: false,
	isVendorUploadEnabled: false
}

const defaultDocumentTemplateFlags = {
	isInvoiceEnabled: false,
	isChallanEnabled: false,
	invoiceType: InvoiceTypes.GENERAL,
	isInvoiceScanEnabled: false,
	invoiceScanType: InvoiceScanTypes.BARCODE
}

const statusNotificationOptions = [
	'Requested',
	'Approved',
	'Shipped',
	'In Transit',
	'Arrived',
	'Completed',
	'Cancelled'
]

const bulkUploadOptions = [
	{
		label: 'Orders',
		value: 'isOrderUploadEnabled'
	},
	{
		label: 'Customers',
		value: 'isCustomerUploadEnabled'
	},
	{
		label: 'Purchase Orders',
		value: 'isPurchaseOrderUploadEnabled'
	},
	{
		label: 'Vendors',
		value: 'isVendorUploadEnabled'
	}
]


const CompanySettings = () => {
	const dispatch = useDispatch()
	const router = useRouter()
	const { query } = router
	const { params } = query
	const { userProfile, role, permissions } = useSelector(state => state.authReducer)
	const { companyDetails } = useSelector(state => state.companiesReducer)
	const [isOmsAppEnabled, setIsOmsAppEnabled] = useState(true)
	const [isDmsAppEnabled, setIsDmsAppEnabled] = useState(false)
	const [isWmsAppEnabled, setIsWmsAppEnabled] = useState(false)
	const [isTmsAppEnabled, setIsTmsAppEnabled] = useState(false)
	const [defaultApp, setDefaultApp] = useState(Apps.OMS)
	const [isDeliveryPlanEnabled, setIsDeliveryPlanEnabled] = useState(false)
	const [isPurchaseOrderEnabled, setIsPurchaseOrderEnabled] = useState(false)
	const [isExpensesEnabled, setIsExpensesEnabled] = useState(false)
	const [isCclCheckEnabled, setIsCclCheckEnabled] = useState(false)
	const [isCclCheckAutomatic, setIsCclCheckAutomatic] = useState(true)
	const [isCustomerDuplicatePhoneNumberEnabled, setIsCustomerDuplicatePhoneNumberEnabled] = useState(false)
	const [isForecastEnabled, setIsForecastEnabled] = useState(false)
	const [isInsightsEnabled, setIsInsightsEnabled] = useState(false)
	const [isFleetEnabled, setIsFleetEnabled] = useState(false)
	const [isVendorEnabled, setIsVendorEnabled] = useState(false)
	const [isInventoryEnabled, setIsInventoryEnabled] = useState(false)
	const [inventoryEdition, setInventoryEdition] = useState('BASIC')
	const [isSubStatusEnabled, setIsSubStatusEnabled] = useState(false)
	const [isSubOrdersEnabled, setIsSubOrdersEnabled] = useState(false)
	const [isTransportVendorsEnabled, setIsTransportVendorsEnabled] = useState(false)
	const [is3plIntegrationsEnabled, setIs3plIntegrationsEnabled] = useState(false)
	const [isEtaNotificationsEnabled, setIsEtaNotificationsEnabled] = useState(false)
	const [isTransportQuotationsEnabled, setIsTransportQuotationsEnabled] = useState(false)
	const [isInventoryUnderstockedEnabled, setIsInventoryUnderstockedEnabled] = useState(false)
	const [isDistributorNotificationsEnabled, setIsDistributorNotificationsEnabled] = useState(false)
	const [distributorOrderNotifications, setDistributorOrderNotifications] = useState(defaultStatusNotificationsOptions)
	const [hotlinePhoneNumber, setHotlinePhoneNumber] = useState('')
	const [distributorOrderNotificationValueType, setDistributorOrderNotificationValueType] = useState(DistributorOrderNotificationValueType.ORDER_VALUE)
	const [isHotlinePhoneNumberEnabled, setIsHotlinePhoneNumberEnabled] = useState(false)
	const [isBulkUploadEnabled, setIsBulkUploadEnabled] = useState(false)
	const [bulkUploadFlags, setBulkUploadFlags] = useState(defaultBulkUploadFlags)
	const [documentTemplateFlags, setDocumentTemplateFlags] = useState(defaultDocumentTemplateFlags)
	const [industry, setIndustry] = useState(null)
	const [industryType, setIndustryType] = useState(null)
	const [enterpriseIndustries, setEnterpriseIndustries] = useState([])
	const [invoiceIdInitial, setInvoiceIdInitial] = useState('')
	const [logoUrl, setLogoUrl] = useState()
	const [apiKey, setApiKey] = useState()
	const [isEditSettingsEnabled, setIsEditSettingsEnabled] = useState(false)
	const [isUpdatingDetails, setIsUpdatingDetails] = useState(false)
	const [isUpdatingApiKey, setIsUpdatingApiKey] = useState(false)
	const [orderStatusFlows, setOrderStatusFlows] = useState([])
	const [orderStatusFlowKey, setOrderStatusFlowKey] = useState('DEFAULT')
	const [selectedApp, setSelectedApp] = useState(Apps.OMS)
	const [isClearDataModalVisible, setIsClearDataModalVisible] = useState(false)
	const [currentSubscriptionPlan, setCurrentSubscriptionPlan] = useState('')
	const [currentSubscriptionPeriod, setCurrentSubscriptionPeriod] = useState(SubscriptionPeriod.MONTHLY.value)
	const [subscriptionStatus, setSubscriptionStatus] = useState('')
	const [subscriptionUsage, setSubscriptionUsage] = useState({})
	const [salesOrderLimit, setSalesOrderLimit] = useState(0)
	const [purchaseOrderLimit, setPurchaseOrderLimit] = useState(0)
	const [totalProductsLimit, setTotalProductsLimit] = useState(0)
	const [companyUsersLimit, setCompanyUsersLimit] = useState(0)
	const [isSubscriptionLimitUpdating, setIsSubscriptionLimitUpdating] = useState(false)
	const [isSyncProcessingStockLoading, setIsSyncProcessingStockLoading] = useState(false)
	const [isSubscriptionLimitsEditable, setIsSubscriptionLimitsEditable] = useState(false)

	const isAdmin = useMemo(() => {
		if (role === claimsRoles.ADMIN) {
			return true
		} else {
			return false
		}
	}, [role])

	const isOwner = useMemo(() => {
		if (role === claimsRoles.OWNER) {
			return true
		} else {
			return false
		}
	}, [role])

	const isEditable = useMemo(() => {
		if (isAdmin) {
			return permissions.editCompanySettings === true
		} else {
			return permissions.editCompanySettings === true || isOwner
		}
	}, [isAdmin, isOwner, permissions])

	const isECommerce = useMemo(() => {
		return industry === Industries.E_COMMERCE
	}, [industry])

	const id = useMemo(() => {
		if (params && params.length > 0) {
			if (isValidUUID(params[0])) {
				return params[0]
			} else {
				return undefined
			}
		} else {
			return undefined
		}
	}, [params])

	const companyId = useMemo(() => {
		const companyId = id ? id : userProfile ? userProfile.companyId : ''
		return companyId
	}, [id, userProfile])

	const modifiedOrderStatusFlows = useMemo(() => {
		if (inventoryEdition === InventoryEditions.ADVANCED) {
			return orderStatusFlows.filter(flow => flow.key === 'FLOW_3')
		}
		if (isECommerce) {
			return orderStatusFlows.filter(flow => flow.key === 'FLOW_2')
		} else {
			return orderStatusFlows.filter(flow => !['FLOW_2', 'FLOW_3'].includes(flow.key))
		}
	}, [isECommerce, orderStatusFlows, inventoryEdition])

	const modifiedStatusNotificationOptions = useMemo(() => {
		if (isECommerce) {
			return statusNotificationOptions
				.filter(option => option !== 'Arrived')
				.map(option => {
					let label = option
					if (label === 'Requested') {
						label = 'Pending'
					}
					if (label === 'Completed') {
						label = 'Delivered'
					}
					return {
						label,
						key: option
					}
				})
		} else {
			return statusNotificationOptions
				.map(option => {
					return {
						label: option === 'Completed' ? 'Delivered' : option,
						key: option === 'Arrived' ? 'Unloaded' : option
					}
				})
		}
	}, [isECommerce])

	const modifiedDistributorOrderNotifications = useMemo(() => {
		if (isECommerce) {
			return {
				...distributorOrderNotifications,
				isUnloadedNotificationEnabled: false
			}
		} else {
			return distributorOrderNotifications
		}
	}, [isECommerce, distributorOrderNotifications])

	const company = companyDetails[id]
	useEffect(() => {
		if (company) {
			setCompanyDetails()
		}
	}, [company])

	useEffect(() => {
		if (userProfile && role !== claimsRoles.ADMIN) {
			setCompanyDetails()
		}
	}, [userProfile])

	useEffect(() => {
		fetchOrderStatusFlows()
		fetchEnterpriseIndustries()
	}, [])

	const previousIndustry = usePrevious(industry)
	useEffect(() => {
		if (previousIndustry !== industry && previousIndustry) {
			if (industry === Industries.E_COMMERCE) {
				setSelectedApp(Apps.OMS)
				setOrderStatusFlowKey('FLOW_2')
				setIsSubOrdersEnabled(false)
				setIsSubStatusEnabled(false)
				setInventoryEdition(InventoryEditions.BASIC)
				setDocumentTemplateFlags({
					...documentTemplateFlags,
					isChallanEnabled: false,
					isInvoiceEnabled: true,
					invoiceType: InvoiceTypes.GENERAL,
					isInvoiceScanEnabled: false,
					invoiceScanType: InvoiceScanTypes.BARCODE
				})
				setIsDeliveryPlanEnabled(false)
				setIsFleetEnabled(false)
			}
		}
	}, [industry])

	useEffect(() => {
		if (inventoryEdition === InventoryEditions.ADVANCED) {
			setOrderStatusFlowKey('FLOW_3')
			setIsSubStatusEnabled(true)
		} else {
			setOrderStatusFlowKey('DEFAULT')
		}
	}, [inventoryEdition])

	useEffect(() => {
		if (permissions.viewCompanySettings === false) {
			router.push('/company/general')
		}
	}, [permissions])

	const fetchEnterpriseIndustries = async () => {
		try {
			const response = await Users.fetchIndustries()
			if (response.data) {
				setEnterpriseIndustries(response.data.filter(industry => industry.value !== Industries.E_COMMERCE))
			}
		} catch (e) {
			console.error(e)
		}
	}

	const fetchOrderStatusFlows = async () => {
		const { data } = await SalesOrders.fetchOrderStatusFlows()
		setOrderStatusFlows(data)
	}

	const onSaveOwnerSettings = async () => {
		try {
			setIsUpdatingDetails(true)
			await dispatch(updateCompanyDetails(companyId, {
				isDistributorNotificationsEnabled,
				distributorOrderNotifications: {
					...modifiedDistributorOrderNotifications,
					isHotlinePhoneNumberEnabled,
					hotlinePhoneNumber: hotlinePhoneNumber || undefined,
					orderValueType: distributorOrderNotificationValueType || DistributorOrderNotificationValueType.ORDER_VALUE
				},
				documentTemplates: documentTemplateFlags,
				logoUrl,
				invoiceIdInitial,
				isCclCheckEnabled,
				isCclCheckAutomatic
			}))
			setIsEditSettingsEnabled(false)
			notification.success({
				message: 'Company Settings Updated',
				description: 'Company settings successfully changed.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			setCompanyDetails()
			notification.error({
				message: 'Unable to Update Company Settings',
				description: e.message,
				placement: 'bottomLeft'
			})
			setIsEditSettingsEnabled(true)
		} finally {
			setIsUpdatingDetails(false)
		}
	}

	const onSaveSettings = async () => {
		try {
			if (!id) {
				return
			}
			setIsUpdatingDetails(true)
			const apps = []
			if (isOmsAppEnabled) {
				apps.push(Apps.OMS)
			}
			if (isDmsAppEnabled) {
				apps.push(Apps.DMS)
			}
			if (isWmsAppEnabled) {
				apps.push(Apps.WMS)
			}
			if (isTmsAppEnabled) {
				apps.push(Apps.TMS)
			}
			if (apps.length === 0) {
				throw new Error('At least one app must be enabled.')
			}
			if (!apps.includes(defaultApp)) {
				throw new Error('Default app cannot be disabled.')
			}
			const selectedIndustry = industry === industryOptions[0].value
				? Industries.E_COMMERCE
				: industryType
			const selectedIndustryType = industry === industryOptions[0].value
				? industryType
				: null
			await dispatch(updateCompanyDetails(id, {
				apps,
				defaultApp,
				isDeliveryPlanEnabled,
				isPurchaseOrderEnabled,
				isFleetEnabled,
				isInventoryEnabled,
				inventoryEdition,
				isVendorEnabled,
				isSubStatusEnabled,
				isSubOrdersEnabled,
				isExpensesEnabled,
				isCustomerDuplicatePhoneNumberEnabled,
				isForecastEnabled,
				isInsightsEnabled,
				isInventoryUnderstockedEnabled,
				isDistributorNotificationsEnabled,
				distributorOrderNotifications: {
					...modifiedDistributorOrderNotifications,
					isHotlinePhoneNumberEnabled,
					hotlinePhoneNumber: hotlinePhoneNumber || undefined,
					orderValueType: distributorOrderNotificationValueType || DistributorOrderNotificationValueType.ORDER_VALUE
				},
				isBulkUploadEnabled,
				bulkUploadFlags,
				isTransportQuotationsEnabled,
				isTransportVendorsEnabled,
				is3plIntegrationsEnabled,
				isEtaNotificationsEnabled,
				documentTemplates: documentTemplateFlags,
				orderStatusFlowKey,
				industry: selectedIndustry,
				industryType: selectedIndustryType,
				logoUrl,
				invoiceIdInitial,
				isCclCheckEnabled,
				isCclCheckAutomatic
			}))
			setIsEditSettingsEnabled(false)
			notification.success({
				message: 'Company Settings Updated',
				description: 'Company settings successfully changed.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			setCompanyDetails()
			notification.error({
				message: 'Unable to Update Company Settings',
				description: e.message,
				placement: 'bottomLeft'
			})
			setIsEditSettingsEnabled(true)
		} finally {
			setIsUpdatingDetails(false)
		}
	}

	const generateApiKey = async () => {
		try {
			setIsUpdatingApiKey(true)
			const { data } = await Companies.updateApiKey(companyId)
			setApiKey(data.apiKey)
			notification.success({
				message: 'Company API Key generated.',
				description: 'Company API Key successfully updated.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Generate API Key',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsUpdatingApiKey(false)
		}
	}

	const setCompanyDetails = () => {
		const company = companyDetails[id]
		let companyDetail = undefined
		if (company) {
			companyDetail = company.companyDetails
		} else if (userProfile && userProfile.company) {
			companyDetail = userProfile.company.companyDetails
		}
		if (companyDetail) {
			const apps = new Set(companyDetail.apps)
			setDefaultApp(companyDetail.defaultApp)
			setIsOmsAppEnabled(apps.has(Apps.OMS))
			setIsDmsAppEnabled(apps.has(Apps.DMS))
			setIsWmsAppEnabled(apps.has(Apps.WMS))
			setIsTmsAppEnabled(apps.has(Apps.TMS))
			setIndustry(
				companyDetail.industry === Industries.E_COMMERCE
					? industryOptions[0].value
					: industryOptions[1].value
			)
			setIndustryType(
				companyDetail.industry === Industries.E_COMMERCE
					? companyDetail.industryType ? companyDetail.industryType : ecommerceIndustryTypeOptions[7].value
					: companyDetail.industry
			)
			setIsDeliveryPlanEnabled(companyDetail.isDeliveryPlanEnabled)
			setIsPurchaseOrderEnabled(companyDetail.isPurchaseOrderEnabled)
			setIsFleetEnabled(companyDetail.isFleetEnabled)
			setIsVendorEnabled(companyDetail.isVendorEnabled)
			setIsTransportVendorsEnabled(companyDetail.isTransportVendorsEnabled)
			setIsExpensesEnabled(companyDetail.isExpensesEnabled)
			setIsCustomerDuplicatePhoneNumberEnabled(companyDetail.isCustomerDuplicatePhoneNumberEnabled)
			setIsForecastEnabled(companyDetail.isForecastEnabled)
			setIsInsightsEnabled(companyDetail.isInsightsEnabled)
			setIs3plIntegrationsEnabled(companyDetail.is3plIntegrationsEnabled)
			setIsEtaNotificationsEnabled(companyDetail.isEtaNotificationsEnabled)
			setIsTransportQuotationsEnabled(companyDetail.isTransportQuotationsEnabled)
			setIsInventoryEnabled(companyDetail.isInventoryEnabled)
			setInventoryEdition(companyDetail.inventoryEdition)
			setIsSubStatusEnabled(companyDetail.isSubStatusEnabled)
			setIsSubOrdersEnabled(companyDetail.isSubOrdersEnabled)
			setIsInventoryUnderstockedEnabled(companyDetail.isInventoryUnderstockedEnabled)
			setIsDistributorNotificationsEnabled(companyDetail.isDistributorNotificationsEnabled)
			if (companyDetail.distributorOrderNotifications) {
				setDistributorOrderNotifications({
					...defaultStatusNotificationsOptions,
					...companyDetail.distributorOrderNotifications
				})
				setDistributorOrderNotificationValueType(companyDetail.distributorOrderNotifications.orderValueType || DistributorOrderNotificationValueType.ORDER_VALUE)
				setIsHotlinePhoneNumberEnabled(companyDetail.distributorOrderNotifications.isHotlinePhoneNumberEnabled)
				setHotlinePhoneNumber(companyDetail.distributorOrderNotifications.hotlinePhoneNumber || '')
			}
			setIsBulkUploadEnabled(companyDetail.isBulkUploadEnabled)
			if (companyDetail.bulkUploadFlags) {
				setBulkUploadFlags({
					...defaultBulkUploadFlags,
					...companyDetail.bulkUploadFlags
				})
			}
			if (companyDetail.documentTemplates) {
				const documentTemplates = { ...companyDetail.documentTemplates }
				if (!documentTemplates.invoiceType) {
					documentTemplates.invoiceType = InvoiceTypes.GENERAL
				}
				if (!documentTemplates.invoiceScanType) {
					documentTemplates.invoiceScanType = InvoiceScanTypes.BARCODE
				}
				documentTemplates.isInvoiceScanEnabled = documentTemplates.isInvoiceScanEnabled || false
				setDocumentTemplateFlags(documentTemplates)
			}
			setLogoUrl(companyDetail.logoUrl)
			setApiKey(companyDetail.apiKey)
			setInvoiceIdInitial(companyDetail.invoiceIdInitial || '')
			if (companyDetail.orderStatusFlow) {
				setOrderStatusFlowKey(companyDetail.orderStatusFlow.key)
			}
			if (companyDetail.industry === Industries.E_COMMERCE) {
				setCurrentSubscriptionPlan(companyDetail.subscriptionPlan)
				setCurrentSubscriptionPeriod(companyDetail.subscriptionPeriod)
				setSubscriptionStatus(companyDetail.subscriptionStatus)
				if (
					(
						companyDetail.subscriptionPlan === SubscriptionTier.BASIC ||
						companyDetail.subscriptionPlan === SubscriptionTier.PRO ||
						companyDetail.subscriptionPlan === SubscriptionTier.ADVANCED ||
						companyDetail.subscriptionPlan === SubscriptionTier.PLATINUM
					) && (
						companyDetail.subscriptionStatus === SubscriptionStatus.ACTIVE ||
						companyDetail.subscriptionStatus === SubscriptionStatus.INCOMPLETE ||
						companyDetail.subscriptionStatus === SubscriptionStatus.TRIALING
					)
				) {
					getSubscriptionUsage(companyDetail.companyId)
				}
			}
			setIsCclCheckEnabled(companyDetail.isCclCheckEnabled)
			setIsCclCheckAutomatic(companyDetail.isCclCheckAutomatic)
		} else {
			setIndustry(industryOptions[0].value)
			setIsDeliveryPlanEnabled(true)
		}
	}

	const onAppChecked = (checked, value) => {
		switch (value) {
			case Apps.OMS:
				setIsOmsAppEnabled(checked)
				break
			case Apps.DMS:
				setIsDmsAppEnabled(checked)
				break
			case Apps.WMS:
				setIsWmsAppEnabled(checked)
				break
			case Apps.TMS:
				setIsTmsAppEnabled(checked)
				break
		}
		setIsEditSettingsEnabled(true)
	}

	const getAppEnabled = value => {
		switch (value) {
			case Apps.OMS:
				return isOmsAppEnabled
			case Apps.DMS:
				return isDmsAppEnabled
			case Apps.WMS:
				return isWmsAppEnabled
			case Apps.TMS:
				return isTmsAppEnabled
			default:
				return false
		}
	}

	const disableDistributorNotifications = () => {
		const distributorOrderNotifications = { ...modifiedDistributorOrderNotifications }
		for (const field of Object.keys(distributorOrderNotifications)) {
			distributorOrderNotifications[field] = false
		}
		setDistributorOrderNotifications(distributorOrderNotifications)
	}

	const updateBulkUploadFlags = (checked) => {
		const newBulkUploadFlags = { ...bulkUploadFlags }
		for (const field of Object.keys(newBulkUploadFlags)) {
			newBulkUploadFlags[field] = checked
		}
		setBulkUploadFlags(newBulkUploadFlags)
	}

	const getSubscriptionUsage = async (companyId) => {
		const response = await Permissions.fetchSubscriptionUsage(companyId)
		const subscriptionUsage = response.data
		setSubscriptionUsage(subscriptionUsage)
		setSalesOrderLimit(subscriptionUsage.createSalesOrdersPerMonth.limit)
		setPurchaseOrderLimit(subscriptionUsage.createPurchaseOrdersPerMonth.limit)
		setTotalProductsLimit(subscriptionUsage.totalProducts.limit)
		setCompanyUsersLimit(subscriptionUsage.companyUsers.limit)
	}

	const updateSubscriptionLimit = async () => {
		const customSubscriptionLimit = {
			[SubscriptionLimit.SALES_ORDERS]: salesOrderLimit,
			[SubscriptionLimit.PURCHASE_ORDERS]: purchaseOrderLimit,
			[SubscriptionLimit.TOTAL_PRODUCTS]: totalProductsLimit,
			[SubscriptionLimit.COMPANY_USERS]: companyUsersLimit
		}
		const hasIncreasedAnyLimit = Object.keys(customSubscriptionLimit).some(key => customSubscriptionLimit[key] > subscriptionUsage[key].limit)
		if (hasIncreasedAnyLimit) {
			const { data } = await Users.index({
				companyId: company.id,
				role: claimsRoles.OWNER
			})
			const ownerPhone = data?.results[0].phone
			const path = `/upsell-invoice/create?companyId=${company.id}&name=${company.name}&phone=${ownerPhone}&address=${company.address}&currency=${company.currency}&salesOrders=${subscriptionUsage[SubscriptionLimit.SALES_ORDERS].limit}_${salesOrderLimit}&purchaseOrders=${subscriptionUsage[SubscriptionLimit.PURCHASE_ORDERS].limit}_${purchaseOrderLimit}&skus=${subscriptionUsage[SubscriptionLimit.TOTAL_PRODUCTS].limit}_${totalProductsLimit}&users=${subscriptionUsage[SubscriptionLimit.COMPANY_USERS].limit}_${companyUsersLimit}`
			router.push(path)
		} else {
			setIsSubscriptionLimitUpdating(true)
			try {
				await Companies.updateSubscriptionUsageLimit(companyId, customSubscriptionLimit)
				await getSubscriptionUsage(companyId)
			} catch (e) {
				notification.error({
					message: 'Unable to update subscription limits',
					description: e.message,
					placement: 'bottomLeft'
				})
			} finally {
				setIsSubscriptionLimitsEditable(false)
				setIsSubscriptionLimitUpdating(false)
			}
		}
	}

	const syncProcessingStock = async () => {
		try {
			setIsSyncProcessingStockLoading(true)
			await Inventory.syncProcessingStock(companyId)
			notification.success({
				message: 'Processing Stock Synced',
				description: 'Processing stock successfully synced.',
				placement: 'bottomLeft'
			})
		} catch (e) {
			notification.error({
				message: 'Unable to Sync Processing Stock',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsSyncProcessingStockLoading(false)
		}
	}

	const onSelectIndustry = value => {
		if (!isAdmin) {
			return
		}
		if (!isEditable) {
			return
		}
		const companyDetail = companyDetails[id].companyDetails
		const isECommerceSelected = value === industryOptions[0].value
		const currentIndustryGroup = companyDetail.industry === Industries.E_COMMERCE
			? industryOptions[0].value
			: industryOptions[1].value
		const isSelectedSameAsCurrent = value === currentIndustryGroup
		setIndustry(value)
		if (!isSelectedSameAsCurrent) {
			if (isECommerceSelected) {
				setIndustryType(ecommerceIndustryTypeOptions[7].value)
			} else {
				setIndustryType(Industries.GENERAL)
			}
		} else {
			if (isECommerceSelected) {
				setIndustryType(companyDetail.industryType ? companyDetail.industryType : ecommerceIndustryTypeOptions[7].value)
			} else {
				setIndustryType(companyDetail.industry)
			}
		}
		setIsEditSettingsEnabled(true)
	}

	const renderDistributorNotificationSettings = () => {
		return (
			<Form.Item
				className={styles.smallSettingsContainer}
				label={
					<div className={styles.formHeader}>
						{isECommerce ? 'Customer Notifications' : 'SMS Notifications'}
					</div>
				}
				tooltip={
					isECommerce ?
						'Enable customers to receive SMS notifications when orders are updated.' :
						'Enable SMS notifications to send SMSes when orders are updated.'
				}
			>
				<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
					<div style={{ color: '#656565', fontSize: 12 }}>{isDistributorNotificationsEnabled ? 'Enabled' : 'Disabled'}</div>
					<Switch
						size='small'
						style={{ alignSelf: 'flex-start', marginLeft: 8 }}
						checked={isDistributorNotificationsEnabled}
						onChange={checked => {
							if (!isEditable) {
								return
							}
							setIsDistributorNotificationsEnabled(checked)
							if (!checked) {
								disableDistributorNotifications()
							}
							setIsEditSettingsEnabled(true)
						}}
					/>
				</span>
				<div style={{ display: 'flex', gap: 16, marginTop: 20 }}>
					{
						modifiedStatusNotificationOptions.map(option => {
							const field = `is${option.key.replace(/\s/g, '')}NotificationEnabled`
							return (
								<Form.Item
									className={styles.noSmallSettingsContainer}
									key={option.key}
									size='small'
									label={<div className={styles.formHeader}>{option.label}</div>}
									tooltip={`Enable SMS notifications to send SMSes when orders are ${option.label.toLowerCase()}.`}
								>
									<Switch
										size='small'
										disabled={!isDistributorNotificationsEnabled}
										checked={modifiedDistributorOrderNotifications[field]}
										onChange={checked => {
											if (!isEditable) {
												return
											}
											setDistributorOrderNotifications({
												...modifiedDistributorOrderNotifications,
												[field]: checked
											})
											setIsEditSettingsEnabled(true)
										}}
									/>
								</Form.Item>
							)
						})
					}
				</div>
				<Form.Item
					className={styles.smallSettingsContainer}
					style={{ marginBottom: 0, marginTop: 12, width: '100%' }}
					label={<div className={styles.formHeader}>Order Value Preference</div>}
					tooltip='Determine what order value should be shown in the customer notification template.'
				>
					<div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
						<Radio.Group
							disabled={!isDistributorNotificationsEnabled}
							value={distributorOrderNotificationValueType}
							onChange={({ target: { value } }) => {
								if (!isEditable) {
									return
								}
								setDistributorOrderNotificationValueType(value)
								setIsEditSettingsEnabled(true)
							}}
						>
							<Radio style={{ fontSize: 13 }} value={DistributorOrderNotificationValueType.ORDER_VALUE}>
								{convertEnumToString(DistributorOrderNotificationValueType.ORDER_VALUE.toLowerCase())}
							</Radio>
							<Radio style={{ fontSize: 13 }} value={DistributorOrderNotificationValueType.OWING_AMOUNT}>
								{convertEnumToString(DistributorOrderNotificationValueType.OWING_AMOUNT.toLowerCase())}
							</Radio>
						</Radio.Group>
					</div>
				</Form.Item>
				<Form.Item
					className={styles.smallSettingsContainer}
					style={{ marginBottom: 0, marginTop: 12, width: '100%' }}
					label={<div className={styles.formHeader}>Hotline</div>}
					tooltip='Include the hotline number in the SMS template.'
				>
					<div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isHotlinePhoneNumberEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isHotlinePhoneNumberEnabled}
								disabled={!isDistributorNotificationsEnabled}
								onChange={checked => {
									if (!isEditable) {
										return
									}
									setIsHotlinePhoneNumberEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
						<Input
							style={{ width: 474 }}
							className='company-input'
							showCount={true}
							value={hotlinePhoneNumber}
							type='tel'
							maxLength={15}
							onChange={e => {
								if (!isEditable) {
									return
								}
								setIsEditSettingsEnabled(true)
								setHotlinePhoneNumber(e.target.value.replace(/[a-zA-Z]+/g, ''))
							}}
							placeholder='Hotline Number'
							disabled={!isHotlinePhoneNumberEnabled || !isDistributorNotificationsEnabled}
						/>
					</div>
				</Form.Item>
			</Form.Item>
		)
	}

	const renderBulkUploadSettings = () => {
		return (
			<Form.Item
				className={styles.smallSettingsContainer}
				label={
					<div className={styles.formHeader}>
						Upload Data in Bulk
					</div>
				}
				tooltip='Enable users to upload data in bulk using CSV files.'
			>
				<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
					<div style={{ color: '#656565', fontSize: 12 }}>{isBulkUploadEnabled ? 'Enabled' : 'Disabled'}</div>
					<Switch
						size='small'
						style={{ alignSelf: 'flex-start', marginLeft: 8 }}
						checked={isBulkUploadEnabled}
						onChange={checked => {
							if (!isAdmin) {
								return
							}
							if (!isEditable) {
								return
							}
							setIsBulkUploadEnabled(checked)
							updateBulkUploadFlags(checked)
							setIsEditSettingsEnabled(true)
						}}
					/>
				</span>
				<div style={{ display: 'flex', gap: 16, marginTop: 20 }}>
					{
						bulkUploadOptions.map(option => {
							return (
								<Form.Item
									className={styles.noSmallSettingsContainer}
									key={option.key}
									size='small'
									label={<div className={styles.formHeader}>{option.label}</div>}
									tooltip={`Enable functionality to upload data in bulk for ${option.label.toLowerCase()}.`}
								>
									<Switch
										size='small'
										disabled={!isBulkUploadEnabled}
										checked={bulkUploadFlags[option.value]}
										onChange={checked => {
											if (!isAdmin) {
												return
											}
											if (!isEditable) {
												return
											}
											setBulkUploadFlags({
												...bulkUploadFlags,
												[option.value]: checked
											})
											setIsEditSettingsEnabled(true)
										}}
									/>
								</Form.Item>
							)
						})
					}
				</div>
			</Form.Item>
		)
	}

	const renderOmsSettings = () => {
		return (
			<div className={styles.settingsContainer}>
				<h3 className={styles.mainHeader} style={{ marginTop: 0, marginBottom: 24 }}>
					{
						isECommerce ?
							'E-Commerce Feature Flags' : 'OMS Feature Flags'
					}
				</h3>
				<Form.Item className={styles.noSmallSettingsContainer}>
					<div style={{ display: 'flex', gap: 16 }}>
						{
							!isECommerce &&
							<Form.Item
								className={styles.smallSettingsContainer}
								label={<div className={styles.formHeader}>Sub-Order Creation</div>}
								tooltip='Enable companies to create sub-orders from their orders.'
								colon={false}
							>
								<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
									<div style={{ color: '#656565', fontSize: 12 }}>{isSubOrdersEnabled ? 'Enabled' : 'Disabled'}</div>
									<Switch
										size='small'
										style={{ alignSelf: 'flex-start', marginLeft: 8 }}
										checked={isSubOrdersEnabled}
										onChange={checked => {
											if (!isAdmin) {
												return
											}
											if (!isEditable) {
												return
											}
											setIsSubOrdersEnabled(checked)
											setIsEditSettingsEnabled(true)
										}}
									/>
								</span>
							</Form.Item>
						}
						{
							isECommerce &&
							<Form.Item
								className={styles.smallSettingsContainer}
								label={<div className={styles.formHeader}>Delivery Success Rate</div>}
								tooltip={'Enable/Disable Delivery Success Rate. Enabling this will allow the user to check the customer delivery success rate for any customer based on the processed orders via various couriers. Select automatic to check the delivery success rate automatically or manual to check the delivery success rate manually when needed.'}
								colon={false}
							>
								<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
									<div style={{ color: '#656565', fontSize: 12 }}>{isCclCheckEnabled ? 'Enabled' : 'Disabled'}</div>
									<Switch
										size='small'
										style={{ alignSelf: 'flex-start', marginLeft: 8 }}
										checked={isCclCheckEnabled}
										onChange={checked => {
											if (!isEditable) {
												return
											}
											setIsCclCheckEnabled(checked)
											setIsEditSettingsEnabled(true)
										}}
									/>
								</span>
								<Radio.Group
									style={{ marginTop: 20 }}
									disabled={!isCclCheckEnabled}
									value={isCclCheckAutomatic}
									onChange={({ target: { value } }) => {
										if (!isEditable) {
											return
										}
										setIsCclCheckAutomatic(value)
										setIsEditSettingsEnabled(true)
									}}
								>
									<Radio style={{ fontSize: 13 }} value={true}>
										Automatic
									</Radio>
									<Radio style={{ fontSize: 13 }} value={false}>
										Manual
									</Radio>
								</Radio.Group>
							</Form.Item>
						}
						<Form.Item
							className={styles.smallSettingsContainer}
							label={<div className={styles.formHeader}>Purchase & Vendor</div>}
							tooltip={'Enable/Disable purchase orders and vendors. Enabling this will make it so purchase orders and vendors affect the company\'s inventory.'}
							colon={false}
						>
							<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
								<div style={{ color: '#656565', fontSize: 12 }}>{isPurchaseOrderEnabled && isVendorEnabled ? 'Enabled' : 'Disabled'}</div>
								<Switch
									size='small'
									style={{ alignSelf: 'flex-start', marginLeft: 8 }}
									checked={isPurchaseOrderEnabled && isVendorEnabled}
									onChange={checked => {
										if (!isAdmin) {
											return
										}
										if (!isEditable) {
											return
										}
										setIsPurchaseOrderEnabled(checked)
										setIsVendorEnabled(checked)
										setIsEditSettingsEnabled(true)
									}}
								/>
							</span>
						</Form.Item>
						<Form.Item
							className={styles.smallSettingsContainer}
							label={<div className={styles.formHeader}>Expenses</div>}
							tooltip={'Enable/Disable expense management for a company.'}
							colon={false}
						>
							<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
								<div style={{ color: '#656565', fontSize: 12 }}>{isExpensesEnabled ? 'Enabled' : 'Disabled'}</div>
								<Switch
									size='small'
									style={{ alignSelf: 'flex-start', marginLeft: 8 }}
									checked={isExpensesEnabled}
									onChange={checked => {
										if (!isAdmin) {
											return
										}
										if (!isEditable) {
											return
										}
										setIsExpensesEnabled(checked)
										setIsEditSettingsEnabled(true)
									}}
								/>
							</span>
						</Form.Item>
						{
							!isECommerce &&
							<Form.Item
								className={styles.smallSettingsContainer}
								label={<div className={styles.formHeader}>Duplicate Phone Number</div>}
								tooltip={'Enable/Disable Duplicate Phone Number. Enabling this will give access to the user to onboard multiple customers with the same phone number.'}
								colon={false}
							>
								<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
									<div style={{ color: '#656565', fontSize: 12 }}>{isCustomerDuplicatePhoneNumberEnabled ? 'Enabled' : 'Disabled'}</div>
									<Switch
										size='small'
										style={{ alignSelf: 'flex-start', marginLeft: 8 }}
										checked={isCustomerDuplicatePhoneNumberEnabled}
										onChange={checked => {
											if (!isEditable) {
												return
											}
											setIsCustomerDuplicatePhoneNumberEnabled(checked)
											setIsEditSettingsEnabled(true)
										}}
									/>
								</span>
							</Form.Item>
						}
					</div>
				</Form.Item>
				{
					!isECommerce &&
					renderDistributorNotificationSettings()
				}
				<div style={{ display: 'flex', gap: 16 }}>
					<Form.Item
						className={styles.smallSettingsContainer}
						label={<div className={styles.formHeader}>Inventory</div>}
						tooltip='Enable/Disable inventory. Will hide inventory from all users within the company. Select basic, standard or enterprise edition for the inventory module.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isInventoryEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isInventoryEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsInventoryEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
						<Radio.Group
							style={{ marginTop: 20 }}
							disabled={!isInventoryEnabled}
							value={inventoryEdition}
							onChange={({ target: { value } }) => {
								if (!isAdmin) {
									return
								}
								if (!isEditable) {
									return
								}
								setInventoryEdition(value)
								setIsEditSettingsEnabled(true)
							}}
						>
							<Radio style={{ fontSize: 13 }} value={InventoryEditions.BASIC}>
								{capitalizeWord(InventoryEditions.BASIC)}
							</Radio>
							<Radio style={{ fontSize: 13 }} value={InventoryEditions.STANDARD}>
								{capitalizeWord(InventoryEditions.STANDARD)}
							</Radio>
							{
								!isECommerce &&
								<>
									<Radio style={{ fontSize: 13 }} value={InventoryEditions.ENTERPRISE}>
										{capitalizeWord(InventoryEditions.ENTERPRISE)}
									</Radio>
									<Radio style={{ fontSize: 13 }} value={InventoryEditions.ADVANCED}>
										{capitalizeWord(InventoryEditions.ADVANCED)}
									</Radio>
								</>
							}
						</Radio.Group>
						{
							isAdmin &&
							<div className={styles.syncProcessingStockContainer}>
								<Button
									onClick={syncProcessingStock}
									loading={isSyncProcessingStockLoading}
								>
									Sync Processing Stock
								</Button>
								<Form.Item style={{ marginTop: 6, marginLeft: 4 }} label=' ' tooltip='Sync the products Processing stock quantity based on the availability of Approved, Processing, Shipped, and In-Transit orders associated products' />
							</div>
						}
					</Form.Item>
					<Form.Item
						className={styles.smallSettingsContainer}
						label={<div className={styles.formHeader}>Understocked Inventory</div>}
						tooltip='Enable alerts that can indicate to a company that their inventory is understocked.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isInventoryUnderstockedEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isInventoryUnderstockedEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsInventoryUnderstockedEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
					<Form.Item
						className={styles.smallSettingsContainer}
						label={<div className={styles.formHeader}>Forecast</div>}
						tooltip='Enable forecasting for data trends.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isForecastEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isForecastEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsForecastEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
					<Form.Item
						className={styles.smallSettingsContainer}
						label={<div className={styles.formHeader}>Insights</div>}
						tooltip='Enable/Disable Insights. Enabling this will generate sales insights and send notifications to the owner.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isInsightsEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isInsightsEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									setIsInsightsEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
				</div>
				<Form.Item
					label={<div className={styles.formHeader}>Order Status Flows</div>}
					tooltip='Changing the flow will affect the flow of the status of the orders.'
				>
					<Radio.Group
						style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}
						onChange={e => {
							if (!isAdmin) {
								return
							}
							if (!isEditable) {
								return
							}
							setOrderStatusFlowKey(e.target.value)
							setIsEditSettingsEnabled(true)
						}}
						value={orderStatusFlowKey}
					>
						{
							modifiedOrderStatusFlows.map((flow) => {
								return (
									<Radio key={flow.key} value={flow.key} style={{ marginBottom: '10px' }}>
										{
											flow.statuses.map((status, index) => {
												return (
													<span key={status} style={{ whiteSpace: 'nowrap' }}>
														{flow.labels[status]}
														{
															flow.statuses.length > index + 1 ? <ArrowRightOutlined style={{ margin: '0 8px' }} /> : ''
														}
													</span>
												)
											})
										}
									</Radio>
								)
							})
						}
					</Radio.Group>
				</Form.Item>
				{
					isECommerce &&
					renderDistributorNotificationSettings()
				}
				{
					isECommerce &&
					renderBulkUploadSettings()
				}
				{
					(!isECommerce && inventoryEdition !== InventoryEditions.ADVANCED) &&
					<div style={{ display: 'flex', alignSelf: 'flex-start' }}>
						<Form.Item
							className={styles.smallSettingsContainer}
							label={<div className={styles.formHeader}>Sub-Status</div>}
							tooltip='Enable/Disable Sub-statuses. Will hide sub-statuses of orders from all users within the company.'
						>
							<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
								<div style={{ color: '#656565', fontSize: 12 }}>{isSubStatusEnabled ? 'Enabled' : 'Disabled'}</div>
								<Switch
									size='small'
									style={{ alignSelf: 'flex-start', marginLeft: 8 }}
									checked={isSubStatusEnabled}
									onChange={checked => {
										if (!isAdmin) {
											return
										}
										if (!isEditable) {
											return
										}
										setIsSubStatusEnabled(checked)
										setIsEditSettingsEnabled(true)
									}}
								/>
							</span>
						</Form.Item>
					</div>
				}
				<h3 className={styles.mainHeader}>Document Templates</h3>
				<div style={{ display: 'flex', gap: 16, marginTop: 20 }}>
					<Form.Item
						className={styles.smallSettingsContainer}
						label={<div className={styles.formHeader}>Invoice</div>}
						tooltip='Enable invoice generation for orders.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{documentTemplateFlags.isInvoiceEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={documentTemplateFlags.isInvoiceEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setDocumentTemplateFlags({
										...documentTemplateFlags,
										isInvoiceEnabled: checked
									})
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
						<Radio.Group
							style={{ marginTop: 20 }}
							disabled={!documentTemplateFlags.isInvoiceEnabled}
							value={documentTemplateFlags.invoiceType ?? InvoiceTypes.GENERAL}
							onChange={({ target: { value } }) => {
								if (!isEditable) {
									return
								}
								setDocumentTemplateFlags({
									...documentTemplateFlags,
									invoiceType: value
								})
								setIsEditSettingsEnabled(true)
							}}
						>
							<Radio style={{ fontSize: 13 }} value={InvoiceTypes.GENERAL}>
								{`${capitalizeWord(InvoiceTypes.GENERAL)} Invoice`}
							</Radio>
							<Radio style={{ fontSize: 13 }} value={InvoiceTypes.POS}>
								{`${InvoiceTypes.POS} Invoice`}
							</Radio>
						</Radio.Group>
						{
							documentTemplateFlags.invoiceType === InvoiceTypes.GENERAL &&
							<Form.Item
								className={styles.smallSettingsContainer}
								style={{ marginTop: 12, marginBottom: 0 }}
								label={<div className={styles.formHeader}>Invoice Scan</div>}
								tooltip='Enable/Disable Invoice Scanning to streamline processing by scanning barcodes or QR codes on invoices. When enabled, the system will automatically generate a barcode or QR code on the invoice, according to the selected preference.'
							>
								<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
									<div style={{ color: '#656565', fontSize: 12 }}>{documentTemplateFlags.isInvoiceScanEnabled ? 'Enabled' : 'Disabled'}</div>
									<Switch
										size='small'
										style={{ alignSelf: 'flex-start', marginLeft: 8 }}
										checked={documentTemplateFlags.isInvoiceScanEnabled}
										onChange={checked => {
											if (!isEditable) {
												return
											}
											setDocumentTemplateFlags({
												...documentTemplateFlags,
												isInvoiceScanEnabled: checked
											})
											setIsEditSettingsEnabled(true)
										}}
									/>
								</span>
								<Radio.Group
									style={{ marginTop: 20 }}
									disabled={!documentTemplateFlags.isInvoiceScanEnabled}
									value={documentTemplateFlags.invoiceScanType ?? InvoiceScanTypes.BARCODE}
									onChange={({ target: { value } }) => {
										if (!isEditable) {
											return
										}
										setDocumentTemplateFlags({
											...documentTemplateFlags,
											invoiceScanType: value
										})
										setIsEditSettingsEnabled(true)
									}}
								>
									<Radio style={{ fontSize: 13 }} value={InvoiceScanTypes.BARCODE}>
										Barcode
									</Radio>
									<Radio style={{ fontSize: 13 }} value={InvoiceScanTypes.QR}>
										QR
									</Radio>
								</Radio.Group>
							</Form.Item>
						}
					</Form.Item>
					{
						!isECommerce &&
						<Form.Item
							style={{ alignSelf: 'flex-start' }}
							className={styles.smallSettingsContainer}
							label={<div className={styles.formHeader}>Challan</div>}
							tooltip='Enable challan generation for orders.'
						>
							<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
								<div style={{ color: '#656565', fontSize: 12 }}>{documentTemplateFlags.isChallanEnabled ? 'Enabled' : 'Disabled'}</div>
								<Switch
									size='small'
									style={{ alignSelf: 'flex-start', marginLeft: 8 }}
									checked={documentTemplateFlags.isChallanEnabled}
									onChange={checked => {
										if (!isEditable) {
											return
										}
										setDocumentTemplateFlags({
											...documentTemplateFlags,
											isChallanEnabled: checked
										})
										setIsEditSettingsEnabled(true)
									}}
								/>
							</span>
						</Form.Item>
					}
				</div>
				<Form.Item
					label={<div className={styles.formHeader}>Invoice ID Initial</div>}
					tooltip='Changing invoice ID initial will determine what will be the prefix for each invoice ID.'
				>
					<div style={{ position: 'relative', width: 474 }}>
						<TextInput
							placeholder='Invoice ID Initial'
							value={invoiceIdInitial}
							maxLength={5}
							onChange={e => {
								if (!isEditable) {
									return
								}
								setIsEditSettingsEnabled(true)
								setInvoiceIdInitial(e.target.value.toUpperCase())
							}}
						/>
						<div className={styles.counter}>
							{invoiceIdInitial?.length ?? 0} / 5
						</div>
					</div>
				</Form.Item>
			</div>
		)
	}

	const renderDmsSettings = () => {
		return (
			<div className={styles.settingsContainer}>
				<h3 className={styles.mainHeader} style={{ marginTop: 0, marginBottom: 24 }}>DMS Feature Flags</h3>
				<div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
					<Form.Item
						label={<div className={styles.formHeader}>Delivery Plans</div>}
						className={styles.smallSettingsContainer}
						tooltip='Enable/Disable delivery plan generation. Will hide delivery plans from all users within the company.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isDeliveryPlanEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isDeliveryPlanEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsDeliveryPlanEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
					<Form.Item
						label={<div className={styles.formHeader}>3PL Integration</div>}
						className={styles.smallSettingsContainer}
						tooltip='Enable/Disable 3PL integration. If enabled, will allow Nuport to book third-party logistics vendor on a company&apos;s behalf.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isDeliveryPlanEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={is3plIntegrationsEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIs3plIntegrationsEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
					<Form.Item
						label={<div className={styles.formHeader}>ETA Notifications</div>}
						className={styles.smallSettingsContainer}
						tooltip='Enable/Disable ETA notifications to send SMS when the routes are set to In-Transit'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isDeliveryPlanEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isEtaNotificationsEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsEtaNotificationsEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
				</div>
			</div>
		)
	}

	const renderWmsSettings = () => {
		return (
			<div className={styles.settingsContainer}>
				<h3 className={styles.mainHeader} style={{ marginTop: 0, marginBottom: 24 }}>WMS Feature Flags</h3>
			</div>
		)
	}

	const renderTmsSettings = () => {
		return (
			<div className={styles.settingsContainer}>
				<h3 className={styles.mainHeader} style={{ marginTop: 0, marginBottom: 24 }}>TMS Feature Flags</h3>
				<div style={{ display: 'flex', flexWrap: 'wrap', gap: 16 }}>
					<Form.Item
						label={<div className={styles.formHeader}>Fleet</div>}
						className={styles.smallSettingsContainer}
						tooltip='Enable/Disable fleet. Will hide fleet from all users within the company.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isFleetEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isFleetEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsFleetEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
					<Form.Item
						label={<div className={styles.formHeader}>Transport Vendor</div>}
						className={styles.smallSettingsContainer}
						tooltip='Enable/Disable transport vendors. Will hide transport vendors from all users within the company.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isTransportVendorsEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isTransportVendorsEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsTransportVendorsEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
					<Form.Item
						label={<div className={styles.formHeader}>Transport Quotation</div>}
						className={styles.smallSettingsContainer}
						tooltip='Enable/Disable transport quotations. Will hide transport quotations from all users within the company.'
					>
						<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
							<div style={{ color: '#656565', fontSize: 12 }}>{isTransportQuotationsEnabled ? 'Enabled' : 'Disabled'}</div>
							<Switch
								size='small'
								style={{ alignSelf: 'flex-start', marginLeft: 8 }}
								checked={isTransportQuotationsEnabled}
								onChange={checked => {
									if (!isAdmin) {
										return
									}
									if (!isEditable) {
										return
									}
									setIsTransportQuotationsEnabled(checked)
									setIsEditSettingsEnabled(true)
								}}
							/>
						</span>
					</Form.Item>
				</div>
			</div>
		)
	}

	const renderSubscriptionInfoSection = () => {
		const subscriptionActiveOrInactive =
			subscriptionStatus === SubscriptionStatus.ACTIVE || subscriptionStatus === SubscriptionStatus.INCOMPLETE || subscriptionStatus === SubscriptionStatus.TRIALING ?
				'Active' :
				'Inactive'
		const isTrial = subscriptionStatus === SubscriptionStatus.TRIALING
		return (
			<div className={styles.settingsContainer}>
				<h3 className={styles.mainHeader}>Subscriptions</h3>
				<div className={styles.subscriptionStatusContainer}>
					<div>
						<span className={styles.subscriptionStatus}>{`${subscriptionActiveOrInactive} Plan:`}</span>
						<span className={styles.subscriptionPlan}>{`${capitalizeWord(currentSubscriptionPlan)}`}</span>
						<span className={styles.subscriptionPeriod}>{`(${capitalizeWord(currentSubscriptionPeriod)})`}</span>
						{isTrial && <span className={styles.trialBadge}>Trial</span>}
					</div>
					{
						isAdmin ?
							isSubscriptionLimitsEditable ?
								<span className={styles.editLimitButtonsContainer}>
									<Button
										type='primary'
										style={{ backgroundColor: '#F2F8FA', padding: '0px', border: '0px', width: '30px', height: '30px' }}
										onClick={() => updateSubscriptionLimit()}
										disabled={isSubscriptionLimitUpdating}
									>
										<CheckOutlined style={{ color: '#288EA5', fontSize: '11px', strokeWidth: '40', stroke: '#288EA5' }} />
									</Button>
									<Button
										type='primary'
										style={{ backgroundColor: '#FFF5F5', padding: '0px', border: '0px', width: '30px', height: '30px' }}
										onClick={() => setIsSubscriptionLimitsEditable(false)}
									>
										<CloseOutlined style={{ color: '#FF5959', fontSize: '11px', strokeWidth: '40', stroke: '#FF5959' }} />
									</Button>
								</span> :
								<Button
									type='primary'
									style={{ backgroundColor: '#F2F8FA', padding: '0px', border: '0px', width: '30px', height: '30px' }}
									onClick={() => setIsSubscriptionLimitsEditable(true)}
									disabled={!permissions.editCompanyPayments}
								>
									<EditOutlined style={{ color: '#288EA5' }} />
								</Button> :
							null
					}
				</div>
				{
					subscriptionStatus === SubscriptionStatus.ACTIVE ||
					subscriptionStatus === SubscriptionStatus.INCOMPLETE ||
					subscriptionStatus === SubscriptionStatus.TRIALING ?
						<div className={styles.subscriptionInfoCardsContainer}>
							<div className={styles.subscriptionInfoCard}>
								<div className={styles.cardHeaderContainer}>
									<h3 className={styles.cardHeader}>Sales Orders</h3>
								</div>
								{
									isSubscriptionLimitsEditable ?
										<div className={styles.limitDefaultContainer}>
											<NumberInput
												title={'Sales Orders Limit (Up To)'}
												min={0}
												value={salesOrderLimit}
												onChange={(value) => setSalesOrderLimit(value)}
											/>
										</div> :
										<>
											<div className={styles.limitDefaultContainer}>
												<p className={styles.limitHeaderText}>Default</p>
												<p className={styles.limitInfo}>{`Up To ${subscriptionUsage?.createSalesOrdersPerMonth?.limit}`}</p>
											</div>
										</>
								}
								<Divider style={{ margin: '0px' }} />
								<div className={styles.subscriptionUsageContainer}>
									<div>
										<p className={styles.limitHeaderText}>Live</p>
										<p className={styles.limitInfo}>{subscriptionUsage?.createSalesOrdersPerMonth?.count}</p>
									</div>
									<div>
										<p className={styles.limitHeaderText}>Available</p>
										<p className={styles.limitInfo}>{subscriptionUsage?.createSalesOrdersPerMonth?.limit - subscriptionUsage?.createSalesOrdersPerMonth?.count}</p>
									</div>
								</div>
							</div>
							{
								isPurchaseOrderEnabled ?
									<div className={styles.subscriptionInfoCard}>
										<div className={styles.cardHeaderContainer}>
											<h3 className={styles.cardHeader}>Purchase Orders</h3>
										</div>
										{
											isSubscriptionLimitsEditable ?
												<div className={styles.limitDefaultContainer}>
													<NumberInput
														title={'Purchase Orders Limit (Up To)'}
														min={0}
														value={purchaseOrderLimit}
														onChange={(value) => setPurchaseOrderLimit(value)}
													/>
												</div> :
												<>
													<div className={styles.limitDefaultContainer}>
														<p className={styles.limitHeaderText}>Default</p>
														<p className={styles.limitInfo}>{`Up To ${subscriptionUsage?.createPurchaseOrdersPerMonth?.limit}`}</p>
													</div>
												</>
										}
										<Divider style={{ margin: '0px' }} />
										<div className={styles.subscriptionUsageContainer}>
											<div>
												<p className={styles.limitHeaderText}>Live</p>
												<p className={styles.limitInfo}>{subscriptionUsage?.createPurchaseOrdersPerMonth?.count}</p>
											</div>
											<div>
												<p className={styles.limitHeaderText}>Available</p>
												<p className={styles.limitInfo}>{subscriptionUsage?.createPurchaseOrdersPerMonth?.limit - subscriptionUsage?.createPurchaseOrdersPerMonth?.count}</p>
											</div>
										</div>
									</div> :
									null
							}
							<div className={styles.subscriptionInfoCard}>
								<div className={styles.cardHeaderContainer}>
									<h3 className={styles.cardHeader}>SKUs</h3>
								</div>
								{
									isSubscriptionLimitsEditable ?
										<div className={styles.limitDefaultContainer}>
											<NumberInput
												title={'SKUs Limit (Up To)'}
												min={0}
												value={totalProductsLimit}
												onChange={(value) => setTotalProductsLimit(value)}
											/>
										</div> :
										<>
											<div className={styles.limitDefaultContainer}>
												<p className={styles.limitHeaderText}>Default</p>
												<p className={styles.limitInfo}>
													{
														subscriptionUsage?.totalProducts?.limit === null ?
															'Advanced Categorization' :
													`Up To ${subscriptionUsage?.totalProducts?.limit}`
													}
												</p>
											</div>
										</>
								}
								<Divider style={{ margin: '0px' }} />
								<div className={styles.subscriptionUsageContainer}>
									<div>
										<p className={styles.limitHeaderText}>Live</p>
										<p className={styles.limitInfo}>{subscriptionUsage?.totalProducts?.count}</p>
									</div>
									<div>
										<p className={styles.limitHeaderText}>Available</p>
										<p className={styles.limitInfo}>
											{
												subscriptionUsage?.totalProducts?.limit === null ?
													'N/A' :
													subscriptionUsage?.totalProducts?.limit - subscriptionUsage?.totalProducts?.count
											}
										</p>
									</div>
								</div>
							</div>
							<div className={styles.subscriptionInfoCard}>
								<div className={styles.cardHeaderContainer}>
									<h3 className={styles.cardHeader}>Users</h3>
								</div>
								{
									isSubscriptionLimitsEditable ?
										<div className={styles.limitDefaultContainer}>
											<NumberInput
												title={'Users Limit (Up To)'}
												min={0}
												value={companyUsersLimit}
												onChange={(value) => setCompanyUsersLimit(value)}
											/>
										</div> :
										<>
											<div className={styles.limitDefaultContainer}>
												<p className={styles.limitHeaderText}>Default</p>
												<p className={styles.limitInfo}>{`Up To ${subscriptionUsage?.companyUsers?.limit}`}</p>
											</div>
										</>
								}
								<Divider style={{ margin: '0px' }} />
								<div className={styles.subscriptionUsageContainer}>
									<div>
										<p className={styles.limitHeaderText}>Live</p>
										<p className={styles.limitInfo}>{subscriptionUsage?.companyUsers?.count}</p>
									</div>
									<div>
										<p className={styles.limitHeaderText}>Available</p>
										<p className={styles.limitInfo}>{subscriptionUsage?.companyUsers?.limit - subscriptionUsage?.companyUsers?.count}</p>
									</div>
								</div>
							</div>
						</div> :
						null
				}
			</div>
		)
	}

	return (
		<>
			{
				!isAdmin &&
				<Affix offsetTop={240} style={{ right: 100, position: 'absolute', top: 0 }}>
					<Alert
						style={{ borderRadius: 0 }}
						className={styles.alert}
						message='Contact Support'
						description='Please ask our support team if you need to change your company settings.'
						type='info'
						showIcon
					/>
				</Affix>
			}
			<Form
				layout='vertical'
				style={{ display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', justifySelf: 'flex-start' }}
			>
				<h3 className={styles.mainHeader}>General</h3>
				<div style={{ display: 'flex', alignItems: 'flex-start' }}>
					<Form.Item
						label={<div className={styles.formHeader}>Company Logo</div>}
						tooltip='Edit the company logo that is displayed.'
						colon={false}
					>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<ImageUpload
								title='Upload Logo'
								width={132}
								height={75}
								image={logoUrl}
								pathname='company-images'
								onImageUploaded={imageUrl => {
									setLogoUrl(imageUrl)
									setIsEditSettingsEnabled(true)
								}}
								disabled={!isEditable}
							/>
						</div>
						<div style={{ color: '#7D7D7D', fontSize: 12 }}>
							Formats: .jpeg, .jpg, .png, .svg
						</div>
						<div style={{ color: '#7D7D7D', fontSize: 12 }}>
							Max Size: 2MB, Resolution: 137px x 75px
						</div>
					</Form.Item>
					{
						role === claimsRoles.ADMIN && permissions.clearCompanyData &&
						<Button
							style={{ marginLeft: 'auto' }}
							onClick={() => setIsClearDataModalVisible(true)}
							disabled={!permissions.clearCompanyData}
							danger={true}
						>
							Clear Data
						</Button>
					}
				</div>
				<Form.Item
					label={<div className={styles.formHeader}>Industry</div>}
					tooltip='Changing the industry will determine what features a company will have.'
				>
					<Select
						className='company-select'
						value={industry}
						style={{ width: 470 }}
						onChange={value => onSelectIndustry(value)}
						options={industryOptions}
						disabled={!isAdmin}
					/>
				</Form.Item>
				<Form.Item
					label={<div className={styles.formHeader}>Industry Type</div>}
					tooltip='Changing the industry type will determine what features a company will have.'
				>
					<Select
						className='company-select'
						value={industryType}
						style={{ width: 470 }}
						onChange={value => {
							if (!isAdmin) {
								return
							}
							if (!isEditable) {
								return
							}
							setIndustryType(value)
							setIsEditSettingsEnabled(true)
						}}
						options={
							industry === industryOptions[0].value
								? ecommerceIndustryTypeOptions
								: enterpriseIndustries
						}
						disabled={!isAdmin}
					/>
				</Form.Item>
				<h3 className={styles.mainHeader}>Integration</h3>
				{
					role !== claimsRoles.ADMIN && userProfile &&
					<Form.Item
						label={<div className={styles.formHeader}>Company ID</div>}
						tooltip='Unique identifier for the company.'
					>
						<Text>
							{userProfile.companyId}
						</Text>
					</Form.Item>
				}
				<Form.Item
					label={<div className={styles.formHeader}>API Key</div>}
					tooltip='API key used to authenticate calls to the Nuport platform. Keep this key secure.'
				>
					<div style={{ display: 'flex' }}>
						<Input.Password
							style={{ width: 474 }}
							className='company-password'
							disabled={apiKey === null || apiKey === undefined}
							value={apiKey}
							placeholder='Generate a new API Key'
						/>
						{
							isEditable && !isNotEmpty(apiKey) ?
								<Popconfirm
									title={<div style={{ width: 200 }}>Are you sure you want to generate an API Key? This will make all current API Keys unusable.</div>}
									onConfirm={generateApiKey}
									okText='Yes'
									cancelText='No'
								>
									<Button
										className='company-button'
										loading={isUpdatingApiKey}
										style={{ width: 138, marginLeft: 8 }}
									>
										Generate
									</Button>
								</Popconfirm> : null
						}
					</div>
				</Form.Item>
				{
					industry === Industries.E_COMMERCE &&
					<Form.Item>
						<StoreIntegrations
							isEditable={isEditable}
							companyId={companyId}
							apiKey={apiKey}
						/>
					</Form.Item>
				}
				{
					!isECommerce &&
					<>
						<h3 className={styles.mainHeader}>Apps</h3>
						<Form.Item>
							<div style={{ display: 'flex', gap: 16 }}>
								{
									appOptions.map(option => {
										const enabled = getAppEnabled(option.value)
										return (
											<div
												key={option.value}
												className={selectedApp === option.value ? styles.appOptionContainerSelected : styles.appOptionContainer}
												onClick={() => setSelectedApp(option.value)}
											>
												<img src={option.icon} className={styles.appIcon} />
												<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 24 }}>
													<span className={styles.formHeader}>
														{option.title}
														<Tooltip title={option.tooltip}>
															<InfoCircleOutlined style={{ marginLeft: 8, color: '#7D7D7D' }} />
														</Tooltip>
													</span>
													<span style={{ display: 'flex', alignItems: 'center', width: 98 }}>
														<div style={{ color: '#656565', fontSize: 12 }}>{enabled ? 'Enabled' : 'Disabled'}</div>
														<Switch
															size='small'
															style={{ alignSelf: 'flex-start', marginLeft: 8 }}
															checked={enabled}
															disabled={option.value === Apps.OMS}
															onChange={checked => {
																if (!isAdmin) {
																	return
																}
																if (!isEditable) {
																	return
																}
																onAppChecked(checked, option.value)
															}}
														/>
													</span>
													{
														defaultApp === option.value ?
															<Button
																style={{ marginLeft: 'auto', marginTop: 12, color: 'black', width: 110 }}
																size='small'
																className='default-button-none'
															>
																Default App
															</Button> :
															<Button
																style={{ marginLeft: 'auto', marginTop: 12, width: 110 }}
																size='small'
																className='default-button'
																onClick={() => {
																	if (!isAdmin) {
																		return
																	}
																	if (!isEditable) {
																		return
																	}
																	setDefaultApp(option.value)
																	setIsEditSettingsEnabled(true)
																}}
																disabled={!enabled}
															>
																Make Default
															</Button>
													}
												</div>
											</div>
										)
									})
								}
							</div>
						</Form.Item>
					</>
				}
				{
					selectedApp === Apps.OMS &&
					renderOmsSettings()
				}
				{
					selectedApp === Apps.DMS &&
					renderDmsSettings()
				}
				{
					selectedApp === Apps.WMS &&
					renderWmsSettings()
				}
				{
					selectedApp === Apps.TMS &&
					renderTmsSettings()
				}
				{
					isECommerce ?
						renderSubscriptionInfoSection() :
						null
				}
				{
					permissions.editCompanySettings === true ?
						<Form.Item>
							<Row className={styles.buttonContainer}>
								<Space size='small' style={{ marginLeft: 'auto' }}>
									<Button
										className='company-default-button-disabled'
										onClick={() => {
											setCompanyDetails()
											setIsEditSettingsEnabled(false)
										}}
										type='ghost'
										loading={isUpdatingDetails}
									>
								Cancel
									</Button>
									<Button
										className='company-default-button'
										onClick={isAdmin ? onSaveSettings : onSaveOwnerSettings}
										type='primary'
										loading={isUpdatingDetails}
										disabled={!isEditSettingsEnabled}
									>
								Save
									</Button>
								</Space>
							</Row>
						</Form.Item> : null
				}
			</Form>
			{
				isClearDataModalVisible &&
				<ClearCompanyDataModal
					companyId={companyId}
					industry={industry}
					visible={isClearDataModalVisible}
					onCancel={() => setIsClearDataModalVisible(false)}
				/>
			}
		</>
	)
}

export default CompanySettings
